import { useContext } from 'react';
import { UserContext } from '../Contexts/UserContext';
import { deleteData } from '../lib/utils/httputils';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';

export function TopBar({ user }) {
  const userContext = useContext(UserContext);
  function doLogout() {
    deleteData('/api/v1/logout').finally(() => {
      userContext.toggleUser({});
    });
  }
  return (
    <div>
      <IconButton onClick={doLogout}>
        <LogoutIcon />
      </IconButton>
    </div>
  );
}
