import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DialogContent from '@mui/material/DialogContent';
import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { ConsumersService } from '../../Services/ConsumersService';
import { HolidaysService } from '../../Services/HolidaysService';
import { UserContext } from '../../Contexts/UserContext';

export function EditHolidayModal({ openHolidayModal, setOpenHolidayModal, onHolidayAdded }) {
  const { user } = useContext(UserContext);
  const [consumerUsername, setConsumerUsername] = useState('');
  const [holidayStartAt, setHolidayStartAt] = useState(new Date());
  const [holidayEndAt, setHolidayEndAt] = useState(new Date());
  const [consumers, setConsumers] = useState([]);
  const [leaveType, setLeaveType] = useState('off');

  useEffect(() => {
    if (openHolidayModal.holiday_id) {
      setConsumerUsername(openHolidayModal.consumer_username);
      setHolidayStartAt(openHolidayModal.date_start);
      setHolidayEndAt(openHolidayModal.date_end);
      setLeaveType(openHolidayModal.type);
      if (consumers.length === 0) {
        if (user.role.includes('MANAGER')) {
          ConsumersService.getConsumers({ provider: 'crm' })
            .then(consumers => {
              setConsumers(consumers);
            })
            .catch(err => {
              console.error(err);
            });
        }
      }
    }
  }, [openHolidayModal]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={!!openHolidayModal.holiday_id}
        onClose={() => setOpenHolidayModal({})}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Holiday</DialogTitle>
        {openHolidayModal && (
          <DialogContent>
            <Box
              component="form"
              sx={{
                pt: 2,
                '& .MuiTextField-root': { mt: 2 }
              }}
              noValidate
              autoComplete="off"
            >
              {user.role.includes('MANAGER') && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Team member</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={consumerUsername}
                    label="Age"
                    onChange={e => setConsumerUsername(e.target.value)}
                  >
                    {consumers.map(consumer => {
                      return (
                        <MenuItem key={consumer.id} value={consumer.username}>
                          {consumer.principalName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <FormControl fullWidth margin="normal">
                <InputLabel id="leave-type-select-label">Leave type</InputLabel>
                <Select
                  labelId="leave-type-select-label"
                  id="leave-type-select"
                  value={leaveType}
                  label="Leave type"
                  onChange={e => setLeaveType(e.target.value)}
                >
                  <MenuItem value="off">Day off</MenuItem>
                  <MenuItem value="sick">Sick</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <DateTimePicker
                  renderInput={props => <TextField {...props} />}
                  label="Start at"
                  value={holidayStartAt}
                  inputFormat="dd/MM/yyyy HH:mm"
                  onChange={newValue => {
                    setHolidayStartAt(newValue);
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <DateTimePicker
                  renderInput={props => <TextField {...props} />}
                  label="End at"
                  value={holidayEndAt}
                  inputFormat="dd/MM/yyyy HH:mm"
                  onChange={newValue => {
                    setHolidayEndAt(newValue);
                  }}
                />
              </FormControl>
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              const holiday = {
                type: leaveType,
                date_start: holidayStartAt,
                date_end: holidayEndAt
              };
              if (consumerUsername) {
                holiday.consumer_username = consumerUsername;
              }
              HolidaysService.editHoliday(openHolidayModal.holiday_id, holiday)
                .then(onHolidayAdded)
                .catch(error => window.alert(error.message));
            }}
          >
            SAVE
          </Button>
          <Button onClick={() => setOpenHolidayModal({})}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
}
