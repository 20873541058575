import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { OnCallService } from '../../Services/OnCallService';
import { ConsumersService } from '../../Services/ConsumersService';
import { ONE_DAY_IN_MS } from '../../lib/utils/dateutils';

export function AddShiftModal({ openShiftModal, setOpenShiftModal, onShiftAdded }) {
  const [consumerUsername, setConsumerUsername] = useState('');
  const d = new Date();
  d.setHours(9, 0, 0, 0);
  const [shiftStartAt, setShiftStartAt] = useState(d);
  const [shiftEndAt, setShiftEndAt] = useState(new Date(d.getTime() + ONE_DAY_IN_MS * 7));
  const [consumers, setConsumers] = useState([]);

  useEffect(() => {
    ConsumersService.getConsumers({ provider: 'crm' })
      .then(consumers => {
        setConsumers(consumers);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openShiftModal}
        onClose={() => setOpenShiftModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add On Call Shift</DialogTitle>
        {openShiftModal && (
          <DialogContent>
            <Box
              component="form"
              sx={{
                pt: 2,
                '& .MuiTextField-root': { mt: 2 }
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Team member</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={consumerUsername}
                  label="Age"
                  onChange={e => setConsumerUsername(e.target.value)}
                >
                  {consumers.map(consumer => {
                    return (
                      <MenuItem key={consumer.id} value={consumer.username}>
                        {consumer.principalName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <DateTimePicker
                  renderInput={props => <TextField {...props} />}
                  label="Start at"
                  value={shiftStartAt}
                  inputFormat="dd/MM/yyyy HH:mm"
                  onChange={newValue => {
                    setShiftStartAt(newValue);
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <DateTimePicker
                  renderInput={props => <TextField {...props} />}
                  label="End at"
                  value={shiftEndAt}
                  inputFormat="dd/MM/yyyy HH:mm"
                  onChange={newValue => {
                    setShiftEndAt(newValue);
                  }}
                />
              </FormControl>
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              OnCallService.addShift({
                consumer_username: consumerUsername,
                shift_start_at: shiftStartAt,
                shift_end_at: shiftEndAt
              })
                .then(onShiftAdded)
                .catch(error => window.alert(error.message));
            }}
          >
            SAVE
          </Button>
          <Button onClick={() => setOpenShiftModal()}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
}
