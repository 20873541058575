import { useEffect, useState } from 'react';
import { UserContext } from './Contexts/UserContext';
import { getData } from './lib/utils/httputils';
import { Dashboard } from './Components/Dashboard';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';

export function App() {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    getData('/api/v1/me').then(user => {
      setUser(user);
      return function cleanup() {};
    });
  }, []);

  function toggleUser(user) {
    setUser(user);
  }

  if (user === undefined) {
    return <div>Loading...</div>;
  }

  if (!user.id) {
    return (
      <Container maxWidth="sm">
        <Grid display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            style={{ width: 200, padding: 20, marginTop: '25%' }}
            onClick={() => (window.location = '/auth/crm')}
          >
            Login via CRM
          </Button>
        </Grid>
      </Container>
    );
  }
  return (
    <UserContext.Provider value={{ user, toggleUser }}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}
