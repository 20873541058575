import { deleteData, getData, postData, putData } from '../lib/utils/httputils';

export class HolidaysService {
  static path = '/api/v1/holidays';
  static async addHoliday(holiday) {
    return postData(HolidaysService.path, holiday);
  }
  static async addHolidays(holidays) {
    return postData(HolidaysService.path, holidays);
  }
  static async editHoliday(holidayId, holiday) {
    return putData(`${HolidaysService.path}/${holidayId}`, holiday);
  }
  static async getHolidays(date) {
    return getData(`${HolidaysService.path}?days=31&from=${date}`);
  }
  static async deleteHoliday(holidayId) {
    return deleteData(`${HolidaysService.path}/${holidayId}`);
  }
}
