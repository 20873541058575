import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getHumanStatus } from './RefundUtils';
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import { toFixedAmountFromCents } from '../../lib/utils/NumberUtils';
import IconButton from '@mui/material/IconButton';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

export function RefundsList({ refunds, onUpdateRefundStatus }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Created at</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refunds.map(row => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <a href={`/api/v1/refunds/${row.id}/file`} target="_blank" rel="noreferrer">
                  <PreviewIcon />
                </a>
                <a href={`/api/v1/refunds/${row.id}/file?dwnl=1`} target="_blank" rel="noreferrer">
                  <DownloadIcon />
                </a>
              </TableCell>
              <TableCell>
                {row.description}
                <br />
                <small>{row.filename}</small>
              </TableCell>
              <TableCell align="right">{toFixedAmountFromCents(row.amount)}</TableCell>
              <TableCell align="right">{getHumanStatus(row.status)}</TableCell>
              <TableCell align="right">{new Date(row.created_at * 1000).toISOString()}</TableCell>
              <TableCell align="right">
                {row.status === 0 && (
                  <IconButton title="Cancel" onClick={() => onUpdateRefundStatus(row.id, 90)}>
                    <DeleteIcon />
                  </IconButton>
                )}
                {row.status === 90 && (
                  <IconButton title="Restore" onClick={() => onUpdateRefundStatus(row.id, 0)}>
                    <RestoreFromTrashIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
