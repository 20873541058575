import Typography from '@mui/material/Typography';
import { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

export function ShowShiftModal({ openShiftModal, setOpenShiftModal, onDeleteShift, setOpenEditShiftModal }) {
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const preDeleteShift = () => {
    setShowDeleteConfirmDialog(true);
  };
  return (
    <>
      <Dialog
        open={showDeleteConfirmDialog}
        onClose={() => setShowDeleteConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete shift?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this shift?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirmDialog(false)}>NO</Button>
          <Button
            onClick={() => {
              setShowDeleteConfirmDialog(false);
              setOpenShiftModal();
              onDeleteShift(openShiftModal.shift_id);
            }}
            autoFocus
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={!!openShiftModal}
        onClose={() => setOpenShiftModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">On Call Shift</DialogTitle>
        {openShiftModal && (
          <DialogContent>
            <Typography id="alert-dialog-description" sx={{ mt: 2 }}>
              On Call: {openShiftModal.consumer_username}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Start at: {openShiftModal.shift_start_at.toLocaleString()}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              End at: {openShiftModal.shift_end_at.toLocaleString()}
            </Typography>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={preDeleteShift}>DELETE</Button>
          <Button
            onClick={() => {
              const editShift = Object.assign({}, openShiftModal);
              setOpenShiftModal();
              setOpenEditShiftModal(editShift);
            }}
          >
            EDIT
          </Button>
          <Button onClick={() => setOpenShiftModal()}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
