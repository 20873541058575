import { useState, useContext, useEffect } from 'react';
import { getMonth, weekdayshort, initDate } from '../../lib/utils/dateutils';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Container from '@mui/material/Container';
import { MenuContext } from '../../Contexts/MenuContext';
import IconButton from '@mui/material/IconButton';
import { CalendarDay } from './CalendarDay';
import { ShowShiftModal } from './ShowShiftModal';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AddShiftModal } from './AddShiftModal';
import { OnCallService } from '../../Services/OnCallService';
import { EditShiftModal } from './EditShiftModal';

function isToday(day) {
  if (!day) return false;
  const now = new Date();
  return (
    now.getUTCFullYear() === day.getUTCFullYear() &&
    now.getUTCMonth() === day.getUTCMonth() &&
    now.getUTCDate() === day.getUTCDate()
  );
}

export function OnCall() {
  const { setMenuTitle } = useContext(MenuContext);

  const [date, setDate] = useState(initDate());
  const [month, setMonth] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [openShiftModal, setOpenShiftModal] = useState();
  const [openAddShiftModal, setOpenAddShiftModal] = useState(false);
  const [openEditShiftModal, setOpenEditShiftModal] = useState({});

  useEffect(() => {
    setMenuTitle('On Call');
  }, []);

  const changeDate = function(next) {
    const d = new Date(date.getTime());
    d.setUTCDate(1);
    d.setUTCMonth(date.getUTCMonth() + (next ? 1 : -1));
    setDate(d);
  };

  const buildMonth = function() {
    const _month = [];
    const day = new Date(date.getTime());
    let c = 0;
    while (true) {
      if (day.getUTCMonth() !== date.getUTCMonth()) break;
      const _line = [];
      for (let i = 0; i < 7; i++) {
        const key = c + 1;
        c++;
        const _day = day.getUTCDay();
        if (_day === i && day.getUTCMonth() === date.getUTCMonth()) {
          _line.push({ day: new Date(day.getTime()), key });
          day.setUTCDate(day.getUTCDate() + 1);
        } else {
          _line.push({ key });
        }
      }
      _month.push(_line);
    }
    setMonth(_month);
  };

  useEffect(() => {
    buildMonth();
    getShifts();
  }, [date]);

  const getShifts = () => {
    OnCallService.getShifts(date.toISOString().split('T')[0])
      .then(shifts => {
        setShifts(shifts);
      })
      .catch(console.error);
  };

  const onDeleteShift = shift_id => {
    OnCallService.deleteShift(shift_id)
      .then(() => {
        getShifts();
      })
      .catch(console.error);
  };

  const onAddShift = () => {
    setOpenAddShiftModal(true);
  };

  return (
    <Container maxWidth="xl">
      <ShowShiftModal
        openShiftModal={openShiftModal}
        setOpenShiftModal={setOpenShiftModal}
        onDeleteShift={onDeleteShift}
        setOpenEditShiftModal={setOpenEditShiftModal}
      />
      <AddShiftModal
        openShiftModal={openAddShiftModal}
        onShiftAdded={() => {
          setOpenAddShiftModal(false);
          getShifts();
        }}
        setOpenShiftModal={setOpenAddShiftModal}
      />
      <EditShiftModal
        openShiftModal={openEditShiftModal}
        onShiftAdded={() => {
          setOpenEditShiftModal({});
          getShifts();
        }}
        setOpenShiftModal={setOpenEditShiftModal}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          margin: '1em 0'
        }}
      >
        <IconButton onClick={() => changeDate(false)}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <span style={{ width: 200, textAlign: 'center' }}>
          {getMonth(date)} {date.getFullYear()}
        </span>
        <IconButton onClick={() => changeDate(true)}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>

      {month.map((line, i) => {
        return (
          <div key={date.getTime() + i}>
            <div style={{ display: 'flex', flexFlow: 'row' }}>
              {line.map((day, p) => {
                const dayStyle = {};
                if (isToday(day.day)) {
                  dayStyle.color = 'white';
                  dayStyle.background = 'black';
                  dayStyle.padding = 4;
                }
                return (
                  <div
                    key={day.key}
                    style={{
                      padding: 8,
                      borderTop: '1px solid #ddd',
                      borderLeft: '1px solid #ddd',
                      borderBottom: i + 1 === month.length ? '1px solid #ddd' : '',
                      borderRight: p === 6 ? '1px solid #ddd' : '',
                      flex: '1 100%',
                      textAlign: 'center',
                      height: i === 0 ? 140 : 120
                    }}
                  >
                    {i === 0 ? <div>{weekdayshort[p]}</div> : ''}
                    {day.day && <span style={dayStyle}>{day.day.getUTCDate()}</span>}
                    {day.day && <CalendarDay shifts={shifts} day={day.day} onShowShift={setOpenShiftModal} />}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <Fab color="primary" aria-label="add" sx={{ position: 'absolute', bottom: 16, right: 16 }} onClick={onAddShift}>
        <AddIcon />
      </Fab>
    </Container>
  );
}
