import { useContext, useEffect, useState } from 'react';
import { MenuContext } from '../../Contexts/MenuContext';
import { ConsumersService } from '../../Services/ConsumersService';
import { ErrorDialog } from '../Common/ErrorDialog';
import { useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { AccountRefunds } from './AccountRefunds';
import { AccountAttachments } from './AccountAttachments';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export function AccountDetail() {
  const [consumer, setConsumer] = useState();
  const [error, setError] = useState();
  const { setMenuTitle } = useContext(MenuContext);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const params = useParams();

  useEffect(() => {
    setMenuTitle(`Accounts > ${consumer ? consumer.principalName : ''}`);
    if (!consumer) {
      loadConsumer();
    }
  }, [consumer]);

  const loadConsumer = () => {
    ConsumersService.getConsumer(params.consumerId)
      .then(res => setConsumer(res))
      .catch(e => setError(e.message));
  };
  return (
    <>
      {error && <ErrorDialog title="ERROR" text={error} onClose={() => setError()} />}
      {consumer && (
        <Paper>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Account" {...a11yProps(0)} />
                <Tab label="Attachments" {...a11yProps(1)} />
                <Tab label="Refunds" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div>Account TBD</div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AccountAttachments consumerId={params.consumerId} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AccountRefunds consumerId={params.consumerId} />
            </TabPanel>
          </Box>
        </Paper>
      )}
    </>
  );
}
