import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { getHumanStatus } from '../Refunds/RefundUtils';
import { toFixedAmountFromCents } from '../../lib/utils/NumberUtils';

export function RefundsTotals({ refunds }) {
  const [totals, setTotals] = useState([]);
  useEffect(() => {
    const statuses = {};
    refunds.forEach(refund => {
      if (!statuses[refund.status]) {
        statuses[refund.status] = 0;
      }
      statuses[refund.status] += refund.amount;
    });
    setTotals(
      Object.keys(statuses)
        .sort()
        .map(k => {
          return { status: k, amount: statuses[k] };
        })
    );
  }, [refunds]);

  return (
    <TableContainer component={Paper} sx={{ maxWidth: 350 }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell align="right">Total Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {totals.map(row => (
            <TableRow key={row.status} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {getHumanStatus(row.status)}
              </TableCell>
              <TableCell align="right">{toFixedAmountFromCents(row.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
