import Container from '@mui/material/Container';
import { useContext, useEffect, useState } from 'react';
import { MenuContext } from '../../Contexts/MenuContext';
import { RefundsService } from '../../Services/RefundsService';
import { RefundsList } from './RefundsList';
import { RefundsTotals } from './RefundsTotals';
import { UserContext } from '../../Contexts/UserContext';
import { ErrorDialog } from '../Common/ErrorDialog';
import Paper from '@mui/material/Paper';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getHumanStatus, getMonthsYears, getStatusesByRole } from '../Refunds/RefundUtils';
import { ConsumersService } from '../../Services/ConsumersService';
import { getCurrentMonthYear } from '../../lib/utils/dateutils';

export function RefundsManager() {
  const { setMenuTitle } = useContext(MenuContext);
  const { user } = useContext(UserContext);
  const [refunds, setRefunds] = useState({});
  const [filters, setFilters] = useState({ status: -1, consumerId: 0, month: getCurrentMonthYear() });
  const [consumers, setConsumers] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    setMenuTitle('Refunds Manager');
    loadConsumers();
  }, []);

  useEffect(() => {
    loadRefunds();
  }, [filters]);

  const loadRefunds = () => {
    RefundsService.getConsumersRefunds(filters)
      .then(res => {
        setRefunds(res);
      })
      .catch(e => {
        console.error(e);
        setError(e.message);
      });
  };

  const loadConsumers = () => {
    ConsumersService.getConsumers({ consumer_type: 'user' })
      .then(res => {
        setConsumers(res);
        loadRefunds();
      })
      .catch(e => {
        setError(e.message);
      });
  };

  const onUpdateRefundStatus = (refundId, status) => {
    RefundsService.updateRefundStatus(refundId, status)
      .then(loadRefunds)
      .catch(e => {
        console.error(e);
        setError(e.message);
      });
  };

  return (
    <Container maxWidth="xl">
      {error && <ErrorDialog title="ERROR" text={error} onClose={() => setError()} />}
      {refunds.total === undefined && <div>Loading...</div>}
      {refunds.total >= 0 && (
        <div
          style={{
            margin: '1em 0'
          }}
        >
          <Paper sx={{ p: '1em' }}>
            <FormControl sx={{ mr: '0.5em' }}>
              <InputLabel id="select-status-label">Account</InputLabel>
              <Select
                labelId="select-status-label"
                id="demo-select-status"
                value={filters.consumerId}
                label="Account"
                onChange={e => setFilters(Object.assign({}, filters, { consumerId: e.target.value }))}
              >
                <MenuItem value={0}>- ALL -</MenuItem>
                {consumers.map(consumer => {
                  return (
                    <MenuItem key={consumer.id} value={consumer.id}>
                      {consumer.principalName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl sx={{ mr: '0.5em' }}>
              <InputLabel id="select-status-label">Month</InputLabel>
              <Select
                labelId="select-status-label"
                id="demo-select-status"
                value={filters.month}
                label="Month"
                onChange={e => setFilters(Object.assign({}, filters, { month: e.target.value }))}
              >
                <MenuItem value="">- ALL -</MenuItem>
                {getMonthsYears().map(monthyear => {
                  return (
                    <MenuItem key={monthyear} value={monthyear}>
                      {monthyear}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl sx={{ mr: '0.5em' }}>
              <InputLabel id="select-status-label">Status</InputLabel>
              <Select
                labelId="select-status-label"
                id="demo-select-status"
                value={filters.status}
                label="Age"
                onChange={e => setFilters(Object.assign({}, filters, { status: e.target.value }))}
              >
                <MenuItem value={-1}>- ALL -</MenuItem>
                {getStatusesByRole(user.role).map(refundStatus => {
                  return (
                    <MenuItem key={refundStatus} value={refundStatus}>
                      {getHumanStatus(refundStatus)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Paper>
        </div>
      )}
      {refunds.total === 0 && <div>No refunds found</div>}
      {refunds.total > 0 && (
        <div
          style={{
            margin: '1em 0'
          }}
        >
          Found {refunds.total} refund(s) - Showing {refunds.data.length} refund(s)
          <RefundsList consumerId={user.id} refunds={refunds.data} onUpdateRefundStatus={onUpdateRefundStatus} />
          <div
            style={{
              margin: '1em 0'
            }}
          >
            <RefundsTotals refunds={refunds.data} />
          </div>
        </div>
      )}
    </Container>
  );
}
