export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
export const ONE_WORKDAY_IN_MS = 1000 * 60 * 60 * 9;

export const getFormattedStringDate = string => {
  const t = new Date(string);
  return t.toLocaleString();
};

export function getCurrentMonthYear() {
  const d = new Date();
  const m = d.getUTCMonth() + 1;
  return `${m < 10 ? '0' : ''}${m}-${d.getUTCFullYear()}`;
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const getMonth = date => {
  return months[date.getMonth()];
};

export const getYear = date => {
  return date.getFullYear();
};

export const weekdayshort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const checkExpiringPassword = expirationDate => {
  const today = new Date();
  const expire = new Date(expirationDate);
  const timeDiscrepancy = expire.getTime() - today.getTime();
  const daysDiscrepancy = timeDiscrepancy / (1000 * 3600 * 24);
  return Math.floor(daysDiscrepancy);
};

export const formatDate = ({ date, format = '%D/%M/%Y', doubleDigit = false }) => {
  if (!date) {
    return '';
  }

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  format = format.replace('%Y', year);
  format = format.replace('%M', month < 10 && doubleDigit ? `0${month}` : month);
  format = format.replace('%D', day < 10 && doubleDigit ? `0${day}` : day);
  return format;
};

export const ISOStringToNumberOfHours = isoString => {
  const date = new Date(isoString);
  return date.getTime() / 1000 / 60 / 60;
};

export const numberOfHoursToISOString = number => {
  const value = number * 60 * 60 * 1000;
  return new Date(value).toISOString();
};

export const initDate = function() {
  const d = new Date();
  d.setUTCDate(1);
  d.setUTCHours(0, 0, 0, 0);
  return d;
};
