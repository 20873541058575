import { useState, useEffect, useContext } from 'react';
import { RefundsService } from '../../Services/RefundsService';
import { ErrorDialog } from '../Common/ErrorDialog';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadIcon from '@mui/icons-material/Download';
import { toFixedAmountFromCents } from '../../lib/utils/NumberUtils';
import { getHumanStatus, getMonthsYears, getStatusesByRole } from '../Refunds/RefundUtils';
import TableContainer from '@mui/material/TableContainer';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getCurrentMonthYear } from '../../lib/utils/dateutils';
import Box from '@mui/material/Box';
import { UserContext } from '../../Contexts/UserContext';

export function AccountRefunds({ consumerId }) {
  const [refunds, setRefunds] = useState({});
  const [error, setError] = useState();
  const { user } = useContext(UserContext);
  const [filters, setFilters] = useState({ consumerId, status: -1, month: getCurrentMonthYear() });
  useEffect(() => {
    loadRefunds();
  }, [filters]);

  const loadRefunds = () => {
    RefundsService.getConsumersRefunds(filters)
      .then(res => {
        setRefunds(res);
      })
      .catch(e => {
        console.error(e);
        setError(e.message);
      });
  };

  return (
    <>
      <Box sx={{ mb: '1em' }}>
        <FormControl sx={{ mr: '0.5em' }}>
          <InputLabel id="select-status-label">Month</InputLabel>
          <Select
            labelId="select-status-label"
            id="demo-select-status"
            value={filters.month}
            label="Month"
            onChange={e => setFilters(Object.assign({}, filters, { month: e.target.value }))}
          >
            {getMonthsYears().map(monthyear => {
              return (
                <MenuItem key={monthyear} value={monthyear}>
                  {monthyear}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="select-status-label">Status</InputLabel>
          <Select
            labelId="select-status-label"
            id="demo-select-status"
            value={filters.status}
            label="Age"
            onChange={e => setFilters(Object.assign({}, filters, { status: e.target.value }))}
          >
            <MenuItem value={-1}>- All -</MenuItem>
            {getStatusesByRole(user.role).map(refundStatus => {
              return (
                <MenuItem key={refundStatus} value={refundStatus}>
                  {getHumanStatus(refundStatus)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      {error && <ErrorDialog title="ERROR" text={error} onClose={() => setError()} />}
      {refunds.data && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Created at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refunds.data.map(row => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <a href={`/api/v1/refunds/${row.id}/file`} target="_blank" rel="noreferrer">
                      <PreviewIcon />
                    </a>
                    <a href={`/api/v1/refunds/${row.id}/file?dwnl=1`} target="_blank" rel="noreferrer">
                      <DownloadIcon />
                    </a>
                  </TableCell>
                  <TableCell>
                    {row.description}
                    <br />
                    <small>{row.filename}</small>
                  </TableCell>
                  <TableCell align="right">{toFixedAmountFromCents(row.amount)}</TableCell>
                  <TableCell align="right">{getHumanStatus(row.status)}</TableCell>
                  <TableCell align="right">{new Date(row.created_at * 1000).toISOString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
