import Container from '@mui/material/Container';
import { useContext, useEffect, useState } from 'react';
import { MenuContext } from '../../Contexts/MenuContext';
import { AttachmentsService } from '../../Services/AttachmentsService';
import { AddAttachmentModal } from './AddAttachmentModal';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import { AttachmentsList } from './AttachmentsList';
import { ErrorDialog } from '../Common/ErrorDialog';
import { UserContext } from '../../Contexts/UserContext';

export function Attachments() {
  const { setMenuTitle } = useContext(MenuContext);
  const { user } = useContext(UserContext);
  const [attachments, setAttachments] = useState({});
  const [filters] = useState({ consumerId: user.id });
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setMenuTitle('Attachments');
    loadAttachments();
  }, [filters]);

  const loadAttachments = () => {
    AttachmentsService.getAttachments(filters)
      .then(res => {
        setAttachments(res);
      })
      .catch(e => {
        console.error(e);
        setError(e.message);
      });
  };

  return (
    <Container maxWidth="xl">
      {error && <ErrorDialog title="ERROR" text={error} onClose={() => setError()} />}
      <AddAttachmentModal
        consumerId={user.id}
        openAttachmentModal={openAttachmentModal}
        onAttachmentAdded={() => {
          setOpenAttachmentModal(false);
          loadAttachments();
        }}
        setOpenAttachmentModal={setOpenAttachmentModal}
      />
      {attachments.total === undefined && <div>Loading...</div>}
      {attachments.total === 0 && <div>No attachments found</div>}
      {attachments.total > 0 && (
        <div
          style={{
            margin: '1em 0'
          }}
        >
          Found {attachments.total} attachment(s) - Showing {attachments.data.length} attachment(s)
          <AttachmentsList attachments={attachments.data} />
        </div>
      )}
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        onClick={() => setOpenAttachmentModal(true)}
      >
        <AddIcon />
      </Fab>
    </Container>
  );
}
