import { deleteData, getData, postData, putData } from '../lib/utils/httputils';

export class OnCallService {
  static path = '/api/v1/oncall/shifts';
  static async addShift(shift) {
    return postData(OnCallService.path, [shift]);
  }
  static async addShifts(shifts) {
    return postData(OnCallService.path, shifts);
  }
  static async editShift(shiftId, shift) {
    return putData(`${OnCallService.path}/${shiftId}`, shift);
  }
  static async getShifts(date) {
    return getData(`${OnCallService.path}?days=31&from=${date}`);
  }
  static async deleteShift(shiftId) {
    return deleteData(`${OnCallService.path}/${shiftId}`);
  }
}
