import React, { useMemo, forwardRef } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink as RouterLink } from 'react-router-dom';
import Settings from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EuroIcon from '@mui/icons-material/Euro';
import HomeIcon from '@mui/icons-material/Home';
import Divider from '@mui/material/Divider';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

function ListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = useMemo(
    () =>
      forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <ListItemButton component={renderLink}>
      {icon ? <ListItemIcon title={primary}>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItemButton>
  );
}

export function mainListItems({ role }) {
  return (
    <React.Fragment>
      <ListItemLink to="/" icon={<HomeIcon />} primary="Home" />
      {role.some(i => ['DEVELOPER', 'SENIOR_DEVELOPER'].includes(i)) && (
        <ListItemLink to="/oncall" icon={<CalendarMonthIcon />} primary="On Call Sched" />
      )}
      {!role.includes('GUEST') && (
        <>
          <ListItemLink to="/holidays-leaves" icon={<BeachAccessIcon />} primary="Holidays & Leaves" />
          <ListItemLink to="/attachments" icon={<PictureAsPdfIcon />} primary="Attachments" />
          <ListItemLink to="/refunds" icon={<EuroIcon />} primary="Refunds" />
        </>
      )}
      <Divider sx={{ my: 1 }} />
    </React.Fragment>
  );
}

export function secondaryListItems({ role, type }) {
  return (
    <React.Fragment>
      {role.some(i => ['ACCOUNT', 'MANAGER'].includes(i)) && (
        <ListItemLink to="/refunds/manager/" icon={<EuroIcon />} primary="Manage Refunds" />
      )}
      {role.some(i => ['ACCOUNT', 'MANAGER'].includes(i)) && (
        <ListItemLink to="/accounts/manager/" icon={<GroupIcon />} primary="Accounts" />
      )}
      <ListItemLink to="/settings" icon={<Settings />} primary="Settings" />
      <Divider sx={{ my: 1 }} />
      {type === 'OWNER' && (
        <>
          <ListItemLink to="/admin" icon={<BuildIcon />} primary="Admin" />
          <Divider sx={{ my: 1 }} />
        </>
      )}
    </React.Fragment>
  );
}
