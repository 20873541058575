import { useState, createRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { AttachmentsService } from '../../Services/AttachmentsService';

export function AddAttachmentModal({ consumerId, openAttachmentModal, setOpenAttachmentModal, onAttachmentAdded }) {
  const [description, setDescription] = useState('');
  const [attachment, setAttachment] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadingError, setUploadingError] = useState('');
  const refCode = createRef();

  const handleSubmit = () => {
    const payload = { name: attachment.name, mimetype: attachment.type, description };
    setUploading(true);

    toBase64(attachment)
      .then(b64 => {
        payload.attachment = b64;
        return AttachmentsService.createAttachment(consumerId, payload);
      })
      .then(() => {
        onAttachmentAdded();
      })
      .catch(err => {
        setUploadingError(err.message);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const toBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const onFileChange = e => {
    // Update the state
    setAttachment(e.target.files[0]);
  };

  const handleCloseError = () => {
    setUploadingError('');
  };
  return (
    <>
      <Snackbar open={!!uploadingError} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', fontSize: '1.4em' }}>
          {uploadingError}
        </Alert>
      </Snackbar>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openAttachmentModal}
        onClose={() => setOpenAttachmentModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Attachment</DialogTitle>
        {openAttachmentModal && (
          <DialogContent>
            <Box
              component="form"
              sx={{
                pt: 2,
                '& .MuiTextField-root': { mt: 2 }
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl fullWidth>
                <TextField
                  required
                  id="attachment-description"
                  label="Description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth>
                <input
                  type="file"
                  className="custom-file-input"
                  id="attachmentInputCode"
                  name="attachment"
                  autoComplete="off"
                  accept="image/*,application/pdf"
                  style={{ padding: '12px 0', marginTop: '16px' }}
                  ref={refCode}
                  onChange={onFileChange}
                />

                {attachment && <div className="text-muted">{attachment.name}</div>}
              </FormControl>
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button disabled={uploading} onClick={handleSubmit}>
            SAVE
          </Button>
          <Button disabled={uploading} onClick={() => setOpenAttachmentModal()}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
