export const REFUND_STATUSES = [0, 5, 10, 80, 90];

export function getStatusesByRole(role) {
  return REFUND_STATUSES.filter(status => {
    if (role.includes('MANAGER')) {
      return status < 90;
    } else if (role.includes('ACCOUNT')) {
      return status >= 5 && status <= 10;
    }
    return false;
  });
}
export function getHumanStatus(status) {
  switch (status) {
    case 0:
    case '0':
      return 'Pending';
    case 5:
    case '5':
      return 'Approved';
    case 10:
    case '10':
      return 'Refunded';
    case 80:
    case '80':
      return 'Rejected';
    case 90:
    case '90':
      return 'Cancelled';
    default:
      return 'Unknown!';
  }
}

const initialYear = 2022;
const initialMonth = 5;

export const getMonthsYears = function getMonthsYears() {
  const now = new Date();
  const currentYear = now.getUTCFullYear();
  const currentMonth = now.getUTCMonth();
  let year = initialYear;
  let month = initialMonth;
  const monthsyears = [];
  while (year <= currentYear) {
    while ((year === currentYear && month <= currentMonth) || (year < currentYear && month < 12)) {
      const m = month + 1;
      monthsyears.push(`${m < 10 ? '0' : ''}${m}-${year}`);
      month++;
      if (month === 12) {
        month = 0;
        break;
      }
    }
    year++;
  }
  return monthsyears.sort(() => -1);
};
