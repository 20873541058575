import { useContext, useEffect } from 'react';
import { MenuContext } from '../Contexts/MenuContext';

export function Home() {
  const { setMenuTitle } = useContext(MenuContext);
  useEffect(() => {
    setMenuTitle('Home');
  }, []);
  return <div></div>;
}
