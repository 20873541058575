import { useContext, useEffect, useState } from 'react';
import { MenuContext } from '../../Contexts/MenuContext';
import { ConsumersService } from '../../Services/ConsumersService';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { ErrorDialog } from '../Common/ErrorDialog';
import { NavLink } from 'react-router-dom';

export function AccountsList() {
  const { setMenuTitle } = useContext(MenuContext);
  const [consumers, setConsumers] = useState([]);
  const [error, setError] = useState();

  const loadConsumers = () => {
    ConsumersService.getConsumers({ consumer_type: 'user' })
      .then(res => {
        setConsumers(res);
      })
      .catch(e => {
        setError(e.message);
      });
  };

  useEffect(() => {
    setMenuTitle('Accounts');
    loadConsumers();
  }, []);

  return (
    <>
      {error && <ErrorDialog title="ERROR" text={error} onClose={() => setError()} />}
      {!consumers && <div>Loading...</div>}
      {consumers && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Principal Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {consumers.map(row => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <NavLink to={`./${row.id}`}>{row.username}</NavLink>
                  </TableCell>
                  <TableCell>{row.principalName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
