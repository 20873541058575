import { useState, useEffect } from 'react';
import { AttachmentsService } from '../../Services/AttachmentsService';
import { ErrorDialog } from '../Common/ErrorDialog';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import PreviewIcon from '@mui/icons-material/Preview';
import DownloadIcon from '@mui/icons-material/Download';
import TableContainer from '@mui/material/TableContainer';
import { AddAttachmentModal } from './AddAttachmentModal';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';

export function AccountAttachments({ consumerId }) {
  const [attachments, setAttachments] = useState({});
  const [error, setError] = useState();
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [filters] = useState({ consumerId });
  useEffect(() => {
    loadAttachments();
  }, [filters]);

  const loadAttachments = () => {
    AttachmentsService.getConsumersAttachments(filters)
      .then(res => {
        setAttachments(res);
      })
      .catch(e => {
        console.error(e);
        setError(e.message);
      });
  };

  return (
    <>
      <AddAttachmentModal
        consumerId={consumerId}
        openAttachmentModal={openAttachmentModal}
        onAttachmentAdded={() => {
          setOpenAttachmentModal(false);
          loadAttachments();
        }}
        setOpenAttachmentModal={setOpenAttachmentModal}
      />
      {error && <ErrorDialog title="ERROR" text={error} onClose={() => setError()} />}
      {attachments.data && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">Uploaded by</TableCell>
                <TableCell align="right">Uploaded at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attachments.data.map(row => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <a href={`/api/v1/attachments/${row.id}/file`} target="_blank" rel="noreferrer">
                      <PreviewIcon />
                    </a>
                    <a href={`/api/v1/attachments/${row.id}/file?dwnl=1`} target="_blank" rel="noreferrer">
                      <DownloadIcon />
                    </a>
                  </TableCell>
                  <TableCell>
                    {row.description}
                    <br />
                    <small>{row.filename}</small>
                  </TableCell>
                  <TableCell align="right">{row.created_by}</TableCell>
                  <TableCell align="right">{new Date(row.created_at * 1000).toISOString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        onClick={() => setOpenAttachmentModal(true)}
      >
        <AddIcon />
      </Fab>
    </>
  );
}
