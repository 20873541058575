import Container from '@mui/material/Container';
import { useEffect, useContext } from 'react';
import { MenuContext } from '../../Contexts/MenuContext';

export function Settings() {
  const { setMenuTitle } = useContext(MenuContext);

  useEffect(() => {
    setMenuTitle('Settings');
  }, []);
  return (
    <Container maxWidth="xl">
      <div
        style={{
          margin: '1em 0'
        }}
      >
        Work in progress
      </div>
    </Container>
  );
}
