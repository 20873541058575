import { getData } from '../lib/utils/httputils';
import qs from 'qs';

export class ConsumersService {
  static path = '/api/v1/consumers';
  static async getConsumers(options = {}) {
    const _qs = qs.stringify(options);
    return getData(`${ConsumersService.path}?${_qs}`);
  }
  static async getConsumer(consumerId) {
    return getData(`${ConsumersService.path}/${consumerId}`);
  }
}
