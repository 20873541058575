import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';

export function AttachmentsList({ attachments }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">Uploaded by</TableCell>
            <TableCell align="right">Uploaded at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments.map(row => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <a href={`/api/v1/attachments/${row.id}/file`} target="_blank" rel="noreferrer">
                  <PreviewIcon />
                </a>
                <a href={`/api/v1/attachments/${row.id}/file?dwnl=1`} target="_blank" rel="noreferrer">
                  <DownloadIcon />
                </a>
              </TableCell>
              <TableCell>
                {row.description}
                <br />
                <small>{row.filename}</small>
              </TableCell>
              <TableCell align="right">{row.created_by}</TableCell>
              <TableCell align="right">{new Date(row.created_at * 1000).toISOString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
