import Container from '@mui/material/Container';
import { useEffect, useContext, useState } from 'react';
import { MenuContext } from '../../Contexts/MenuContext';
import { ShowHolidayModal } from './ShowHolidayModal';
import { AddHolidayModal } from './AddHolidayModal';
import { EditHolidayModal } from './EditHolidayModal';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getMonth, initDate, weekdayshort } from '../../lib/utils/dateutils';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CalendarDay } from './CalendarDay';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { HolidaysService } from '../../Services/HolidaysService';

export function HolidayLeaves() {
  const { setMenuTitle } = useContext(MenuContext);
  const [date, setDate] = useState(initDate());
  const [month, setMonth] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [openHolidayModal, setOpenHolidayModal] = useState();
  const [openAddHolidayModal, setOpenAddHolidayModal] = useState(false);
  const [openEditHolidayModal, setOpenEditHolidayModal] = useState({});

  useEffect(() => {
    setMenuTitle('Holiday & Leaves');
  }, []);

  const changeDate = function(next) {
    const d = new Date(date.getTime());
    d.setUTCDate(1);
    d.setUTCMonth(date.getUTCMonth() + (next ? 1 : -1));
    setDate(d);
  };

  const buildMonth = function() {
    const _month = [];
    const day = new Date(date.getTime());
    let c = 0;
    while (true) {
      if (day.getUTCMonth() !== date.getUTCMonth()) break;
      const _line = [];
      for (let i = 0; i < 7; i++) {
        const key = c + 1;
        c++;
        const _day = day.getUTCDay();
        if (_day === i && day.getUTCMonth() === date.getUTCMonth()) {
          _line.push({ day: new Date(day.getTime()), key });
          day.setUTCDate(day.getUTCDate() + 1);
        } else {
          _line.push({ key });
        }
      }
      _month.push(_line);
    }
    setMonth(_month);
  };

  useEffect(() => {
    buildMonth();
    getHolidays();
  }, [date]);

  const getHolidays = () => {
    HolidaysService.getHolidays(date.toISOString().split('T')[0])
      .then(holidays => {
        setHolidays(holidays);
      })
      .catch(console.error);
  };

  const onDeleteHoliday = holiday_id => {
    HolidaysService.deleteHoliday(holiday_id)
      .then(() => {
        getHolidays();
      })
      .catch(console.error);
  };

  const onAddHoliday = () => {
    setOpenAddHolidayModal(true);
  };

  return (
    <Container maxWidth="xl">
      <ShowHolidayModal
        openHolidayModal={openHolidayModal}
        setOpenHolidayModal={setOpenHolidayModal}
        onDeleteHoliday={onDeleteHoliday}
        setOpenEditHolidayModal={setOpenEditHolidayModal}
      />
      <h2>{date.toISOString()}</h2>
      <AddHolidayModal
        openHolidayModal={openAddHolidayModal}
        onHolidayAdded={() => {
          setOpenAddHolidayModal(false);
          getHolidays();
        }}
        setOpenHolidayModal={setOpenAddHolidayModal}
      />
      <EditHolidayModal
        openHolidayModal={openEditHolidayModal}
        onHolidayAdded={() => {
          setOpenEditHolidayModal({});
          getHolidays();
        }}
        setOpenHolidayModal={setOpenEditHolidayModal}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          margin: '1em 0'
        }}
      >
        <IconButton onClick={() => changeDate(false)}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <span style={{ width: 200, textAlign: 'center' }}>
          {getMonth(date)} {date.getFullYear()}
        </span>
        <IconButton onClick={() => changeDate(true)}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>

      {month.map((line, i) => {
        return (
          <div key={date.getTime() + i}>
            <div style={{ display: 'flex', flexFlow: 'row' }}>
              {line.map((day, p) => {
                return (
                  <div
                    key={day.key}
                    style={{
                      padding: 8,
                      borderTop: '1px solid #ddd',
                      borderLeft: '1px solid #ddd',
                      borderBottom: i + 1 === month.length ? '1px solid #ddd' : '',
                      borderRight: p === 6 ? '1px solid #ddd' : '',
                      flex: '1 100%',
                      textAlign: 'center',
                      height: i === 0 ? 140 : 120
                    }}
                  >
                    {i === 0 ? <div>{weekdayshort[p]}</div> : ''}
                    {day.day && <span>{day.day.getUTCDate()}</span>}
                    {day.day && <CalendarDay holidays={holidays} day={day.day} onShowHoliday={setOpenHolidayModal} />}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <span>* not confirmed</span>
      <Fab color="primary" aria-label="add" sx={{ position: 'absolute', bottom: 16, right: 16 }} onClick={onAddHoliday}>
        <AddIcon />
      </Fab>
    </Container>
  );
}
