import { getUsernameColors } from '../../lib/utils/ColorUtils';
import { ONE_DAY_IN_MS } from '../../lib/utils/dateutils';

export function ShiftLine({ shift, day }) {
  const { backgroundColor, color } = getUsernameColors(shift.consumer_username);
  const dayStart = new Date(day.getTime());
  dayStart.setUTCHours(0, 0, 0, 0);
  const dayEnd = new Date(day.getTime());
  dayEnd.setUTCHours(23, 59, 59, 999);
  let begin;
  if (shift.shift_start_at.getTime() < dayStart.getTime()) {
    begin = 0;
  } else {
    // x : 100 = start : ONE_DAY_IN_MS -> x = start * 100 / ONE_DAY_IN_MS
    begin = ((shift.shift_start_at.getTime() - dayStart.getTime()) * 100) / ONE_DAY_IN_MS;
  }
  let end;
  if (shift.shift_end_at.getTime() > dayEnd.getTime()) {
    end = 100 - begin;
  } else {
    // x : 100 = end : ONE_DAY_IN_MS -> x = end * 100 / ONE_DAY_IN_MS
    end = ((shift.shift_end_at.getTime() - dayStart.getTime()) * 100) / ONE_DAY_IN_MS;
  }
  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div style={{ backgroundColor, color, position: 'absolute', left: `${begin}%`, width: `${end}%` }}>
          {shift.consumer_username}
        </div>
      </div>
    </div>
  );
}
