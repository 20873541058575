import { ShiftLine } from './ShiftLine';
import { ONE_DAY_IN_MS } from '../../lib/utils/dateutils';

export function CalendarDay({ shifts, day, onShowShift }) {
  const dayshifts = [];
  shifts.forEach(shift => {
    if (typeof shift.shift_start_at === 'string') {
      shift.shift_start_at = new Date(shift.shift_start_at);
      shift.shift_end_at = new Date(shift.shift_end_at);
    }
    const dstart = day.getTime();
    const dend = dstart + ONE_DAY_IN_MS;
    if (dend > shift.shift_start_at.getTime() && dstart < shift.shift_end_at.getTime()) {
      dayshifts.push(shift);
    }
  });
  dayshifts.sort((a, b) => {
    if (a.shift_start_at.getTime() < b.shift_start_at.getTime()) {
      return 1;
    }
    return -1;
  });
  return (
    <div>
      {dayshifts.map(shift => {
        return (
          <div
            key={shift.shift_id}
            style={{ cursor: 'pointer', width: '100%', height: 24 }}
            onClick={() => onShowShift(shift)}
          >
            <ShiftLine shift={shift} day={day} />
          </div>
        );
      })}
    </div>
  );
}
