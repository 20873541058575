import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { TopBar } from './TopBar';
import { useContext, useState } from 'react';
import { UserContext } from '../Contexts/UserContext';
import { Routes, Route } from 'react-router-dom';
import { Home } from './Home';
import { OnCall } from './OnCall';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MenuContext } from '../Contexts/MenuContext';
import { mainListItems, secondaryListItems } from './MenuListItems';
import { Refunds } from './Refunds';
import { RefundsManager } from './RefundsManager';
import { AccountsManager } from './AccountsManager';
import { HolidayLeaves } from './HolidayLeaves';
import { Settings } from './Settings';
import { Attachments } from './Attachments';

const mdTheme = createTheme();

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9)
      }
    })
  }
}));

export function Dashboard() {
  const userContext = useContext(UserContext);
  const [open, setOpen] = useState(true);
  const [menuTitle, setMenuTitle] = useState('Home');
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const { user } = userContext;
  return (
    <ThemeProvider theme={mdTheme}>
      <MenuContext.Provider value={{ setMenuTitle }}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: '24px' // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' })
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                {menuTitle}
              </Typography>
              {/*
              <IconButton color="inherit">
                <Badge badgeContent={4} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton color="inherit">
                <Person />
              </IconButton>
              */}
              <TopBar user={user} />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1]
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              {mainListItems({ role: user.role })}
              {secondaryListItems({ role: user.role, type: user.type })}
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: theme =>
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto'
            }}
          >
            <Toolbar />
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="settings/*" element={<Settings />} />
              <Route path="oncall/*" element={<OnCall />} />
              <Route path="attachments/*" element={<Attachments />} />
              <Route path="refunds/manager/*" element={<RefundsManager />} />
              <Route path="refunds/*" element={<Refunds />} />
              <Route path="accounts/manager/*" element={<AccountsManager />} />
              <Route path="holidays-leaves/*" element={<HolidayLeaves />} />
            </Routes>
          </Box>
        </Box>
      </MenuContext.Provider>
    </ThemeProvider>
  );
}
