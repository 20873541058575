import Container from '@mui/material/Container';
import { useContext, useEffect, useState } from 'react';
import { MenuContext } from '../../Contexts/MenuContext';
import { RefundsService } from '../../Services/RefundsService';
import { AddRefundModal } from './AddRefundModal';
import AddIcon from '@mui/icons-material/Add';
import { Fab, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { RefundsList } from './RefundsList';
import { RefundsTotals } from './RefundsTotals';
import Paper from '@mui/material/Paper';
import { getHumanStatus, getMonthsYears, REFUND_STATUSES } from './RefundUtils';
import { ConfirmDialog } from '../Common/ConfirmDialog';
import { ErrorDialog } from '../Common/ErrorDialog';
import { getCurrentMonthYear } from '../../lib/utils/dateutils';

export function Refunds() {
  const { setMenuTitle } = useContext(MenuContext);

  const [refunds, setRefunds] = useState({});
  const [filters, setFilters] = useState({ status: 0, month: getCurrentMonthYear() });
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [error, setError] = useState();
  const [currentRefundId, setCurrentRefundId] = useState(0);

  useEffect(() => {
    setMenuTitle('Refunds');
    loadRefunds();
  }, [filters]);

  const loadRefunds = () => {
    RefundsService.getRefunds(filters)
      .then(res => {
        setRefunds(res);
      })
      .catch(e => {
        console.error(e);
        setError(e.message);
      });
  };

  const onUpdateRefundStatus = (refundId, status) => {
    if (status === 90) {
      onCancelRefund(refundId);
    } else {
      RefundsService.updateRefundStatus(refundId, status)
        .then(() => {
          loadRefunds();
        })
        .catch(e => {
          console.error(e);
          setError(e.message);
        });
    }
  };

  const onCancelRefund = refundId => {
    setCurrentRefundId(refundId);
  };

  const doCancelRefund = () => {
    // call
    RefundsService.updateRefundStatus(currentRefundId, 90)
      .then(() => {
        loadRefunds();
      })
      .catch(e => {
        console.error(e);
        setError(e.message);
      })
      .finally(() => {
        setCurrentRefundId(0);
      });
  };

  return (
    <Container maxWidth="xl">
      {error && <ErrorDialog title="ERROR" text={error} onClose={() => setError()} />}
      {currentRefundId > 0 && (
        <ConfirmDialog
          title="Are you sure?"
          text="Do you want to cancel refund's request?"
          onAbort={() => {
            setCurrentRefundId(0);
          }}
          onConfirm={doCancelRefund}
        />
      )}
      <AddRefundModal
        openRefundModal={openRefundModal}
        onRefundAdded={() => {
          setOpenRefundModal(false);
          loadRefunds();
        }}
        setOpenRefundModal={setOpenRefundModal}
      />
      {refunds.total === undefined && <div>Loading...</div>}
      {refunds.total >= 0 && (
        <div
          style={{
            margin: '1em 0'
          }}
        >
          <Paper sx={{ p: '1em' }}>
            <FormControl sx={{ mr: '0.5em' }}>
              <InputLabel id="select-status-label">Month</InputLabel>
              <Select
                labelId="select-status-label"
                id="demo-select-status"
                value={filters.month}
                label="Month"
                onChange={e => setFilters(Object.assign({}, filters, { month: e.target.value }))}
              >
                {getMonthsYears().map(monthyear => {
                  return (
                    <MenuItem key={monthyear} value={monthyear}>
                      {monthyear}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="select-status-label">Status</InputLabel>
              <Select
                labelId="select-status-label"
                id="demo-select-status"
                value={filters.status}
                label="Age"
                onChange={e => setFilters(Object.assign({}, filters, { status: e.target.value }))}
              >
                {REFUND_STATUSES.map(refundStatus => {
                  return (
                    <MenuItem key={refundStatus} value={refundStatus}>
                      {getHumanStatus(refundStatus)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Paper>
        </div>
      )}
      {refunds.total === 0 && <div>No refunds found</div>}
      {refunds.total > 0 && (
        <div
          style={{
            margin: '1em 0'
          }}
        >
          Found {refunds.total} refund(s) - Showing {refunds.data.length} refund(s)
          <RefundsList refunds={refunds.data} onUpdateRefundStatus={onUpdateRefundStatus} />
          <div
            style={{
              margin: '1em 0'
            }}
          >
            <RefundsTotals refunds={refunds.data} />
          </div>
        </div>
      )}
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        onClick={() => setOpenRefundModal(true)}
      >
        <AddIcon />
      </Fab>
    </Container>
  );
}
