import Typography from '@mui/material/Typography';
import { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

export function ShowHolidayModal({ openHolidayModal, setOpenHolidayModal, onDeleteHoliday, setOpenEditHolidayModal }) {
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const preDeleteHoliday = () => {
    setShowDeleteConfirmDialog(true);
  };
  return (
    <>
      <Dialog
        open={showDeleteConfirmDialog}
        onClose={() => setShowDeleteConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete holiday?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this holiday?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirmDialog(false)}>NO</Button>
          <Button
            onClick={() => {
              setShowDeleteConfirmDialog(false);
              setOpenHolidayModal();
              onDeleteHoliday(openHolidayModal.holiday_id);
            }}
            autoFocus
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={!!openHolidayModal}
        onClose={() => setOpenHolidayModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Holidays / Leaves</DialogTitle>
        {openHolidayModal && (
          <DialogContent>
            <Typography id="alert-dialog-description" sx={{ mt: 2 }}>
              {openHolidayModal.consumer_username}
            </Typography>
            <Typography id="alert-dialog-description" sx={{ mt: 2 }}>
              {openHolidayModal.type === 'off' ? 'Day off' : 'Sick leave'}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Start at: {openHolidayModal.date_start.toLocaleString()}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              End at: {openHolidayModal.date_end.toLocaleString()}
            </Typography>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={preDeleteHoliday}>DELETE</Button>
          <Button
            onClick={() => {
              const editHoliday = Object.assign({}, openHolidayModal);
              setOpenHolidayModal();
              setOpenEditHolidayModal(editHoliday);
            }}
          >
            EDIT
          </Button>
          <Button onClick={() => setOpenHolidayModal()}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
