import { getUsernameColors } from '../../lib/utils/ColorUtils';
import { ONE_DAY_IN_MS } from '../../lib/utils/dateutils';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';

export function HolidayLine({ holiday, day }) {
  const { backgroundColor, color } = getUsernameColors(holiday.consumer_username);
  const dayStart = new Date(day.getTime());
  dayStart.setUTCHours(0, 0, 0, 0);
  const dayEnd = new Date(day.getTime());
  dayEnd.setUTCHours(23, 59, 59, 999);
  let begin;
  if (holiday.date_start.getTime() < dayStart.getTime()) {
    begin = 0;
  } else {
    // x : 100 = start : ONE_DAY_IN_MS -> x = start * 100 / ONE_DAY_IN_MS
    begin = ((holiday.date_start.getTime() - dayStart.getTime()) * 100) / ONE_DAY_IN_MS;
  }
  let end;
  if (holiday.date_end.getTime() > dayEnd.getTime()) {
    end = 100 - begin;
  } else {
    // x : 100 = end : ONE_DAY_IN_MS -> x = end * 100 / ONE_DAY_IN_MS
    end = ((holiday.date_end.getTime() - dayStart.getTime()) * 100) / ONE_DAY_IN_MS;
  }
  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div style={{ backgroundColor, color, position: 'absolute', left: `${begin}%`, width: `${end}%` }}>
          {holiday.consumer_username} {holiday.status === 0 ? '*' : ''}
          <div style={{ display: 'inline-block' }}>
            {holiday.type === 'sick' ? (
              <span title="sick leave">
                <LocalHospitalIcon />
              </span>
            ) : (
              <span title="day off">
                <SelfImprovementIcon />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
