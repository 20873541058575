import { getData, postData } from '../lib/utils/httputils';
import qs from 'qs';

export class AttachmentsService {
  static path = '/api/v1/attachments';

  static convertMonthToRange(options) {
    if ('month' in options) {
      if (options.month) {
        const [month, year] = options.month.split('-');
        const startD = new Date(`${year}-${month}-01T00:00:00Z`);
        const endD = new Date(startD.getTime());
        endD.setUTCMonth(startD.getUTCMonth() + 1);
        options.fromDate = Math.round(startD.getTime() / 1000);
        options.toDate = Math.round(endD.getTime() / 1000);
      }
      delete options.month;
    }
  }

  static async getAttachments(filters = {}) {
    const options = Object.assign({}, filters);
    AttachmentsService.convertMonthToRange(options);
    const _qs = qs.stringify(options);
    return getData(`${AttachmentsService.path}?${_qs}`);
  }

  static async getConsumersAttachments(filters = {}) {
    const options = Object.assign({}, filters);
    AttachmentsService.convertMonthToRange(options);
    const _qs = qs.stringify(options);
    return getData(`${AttachmentsService.path}?${_qs}`);
  }

  static async createAttachment(consumerId, data) {
    const _qs = qs.stringify({ consumerId });
    return postData(`${AttachmentsService.path}?${_qs}`, data);
  }
}
