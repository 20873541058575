import { getData, postData, putData } from '../lib/utils/httputils';
import qs from 'qs';

export class RefundsService {
  static path = '/api/v1/refunds';
  static managerPath = '/api/v1/refunds/manager';

  static convertMonthToRange(options) {
    if ('month' in options) {
      if (options.month) {
        const [month, year] = options.month.split('-');
        const startD = new Date(`${year}-${month}-01T00:00:00Z`);
        const endD = new Date(startD.getTime());
        endD.setUTCMonth(startD.getUTCMonth() + 1);
        options.fromDate = Math.round(startD.getTime() / 1000);
        options.toDate = Math.round(endD.getTime() / 1000);
      }
      delete options.month;
    }
  }

  static async getRefunds(filters = {}) {
    const options = Object.assign({}, filters);
    RefundsService.convertMonthToRange(options);
    const _qs = qs.stringify(options);
    return getData(`${RefundsService.path}?${_qs}`);
  }

  static async getConsumersRefunds(filters = {}) {
    const options = Object.assign({}, filters);
    RefundsService.convertMonthToRange(options);
    const _qs = qs.stringify(options);
    return getData(`${RefundsService.managerPath}?${_qs}`);
  }

  static async createRefund(data) {
    return postData(RefundsService.path, data);
  }

  static async updateRefundStatus(refundId, status) {
    return putData(`${RefundsService.path}/${refundId}`, { status });
  }
}
