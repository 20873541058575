import React from 'react';
import { createRoot } from 'react-dom/client';
import Plausible from 'plausible-tracker';
import { App } from './App';
import { getData } from './lib/utils/httputils';

const UnsupportedBrowser = function() {
  return (
    <div className="p-5 m-5">
      <div className="alert alert-warning">
        <h2>ERROR</h2>
        Your browser does not have some features used by this application
        <br />
        <br />
        <a href="https://browsehappy.com/">Please upgrade it or use another one</a>
      </div>
    </div>
  );
};

const initPlausible = function(config) {
  const { domain, apiHost } = config;
  if (!domain || !apiHost) return;
  window.plausible = Plausible(config);
  window.plausible.enableAutoPageviews();
};

const container = document.getElementById('app');

const root = createRoot(container);

if (!window.fetch || typeof window.fetch !== 'function') {
  root.render(<UnsupportedBrowser />);
} else {
  getData('/config.json')
    .then(config => {
      initPlausible(config);
    })
    .catch(e => {
      // console.error('bum', e.message);
    })
    .finally(() => {
      root.render(<App />);
    });
}
