import { Routes, Route } from 'react-router-dom';
import { AccountsList } from './AccountsList';
import Container from '@mui/material/Container';
import { AccountDetail } from './AccountDetail';

export function AccountsManager() {
  return (
    <Container maxWidth="xl">
      <div
        style={{
          margin: '1em 0'
        }}
      >
        <Routes>
          <Route path="" element={<AccountsList />} />
          <Route path=":consumerId" element={<AccountDetail />} />
        </Routes>
      </div>
    </Container>
  );
}
