import { ONE_DAY_IN_MS } from '../../lib/utils/dateutils';
import { HolidayLine } from './HolidaysLine';

export function CalendarDay({ holidays, day, onShowHoliday }) {
  const dayholidays = [];
  holidays.forEach(holiday => {
    if (typeof holiday.date_start === 'string') {
      holiday.date_start = new Date(holiday.date_start);
      holiday.date_end = new Date(holiday.date_end);
    }
    const dstart = day.getTime();
    const dend = dstart + ONE_DAY_IN_MS;
    if (dend > holiday.date_start.getTime() && dstart < holiday.date_end.getTime()) {
      dayholidays.push(holiday);
    }
  });
  dayholidays.sort((a, b) => {
    if (a.date_start.getTime() < b.date_start.getTime()) {
      return 1;
    }
    return -1;
  });
  return (
    <div>
      {dayholidays.map(holiday => {
        return (
          <div
            key={holiday.holiday_id}
            style={{ cursor: 'pointer', width: '100%', height: 24 }}
            onClick={() => onShowHoliday(holiday)}
          >
            <HolidayLine holiday={holiday} day={day} />
          </div>
        );
      })}
    </div>
  );
}
